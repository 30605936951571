<script setup>
import Overlay2 from '@/components/Overlay2.vue'

import { computed, onMounted, onUnmounted, ref } from 'vue'
import { useRoute, onBeforeRouteUpdate } from 'vue-router'

const route = useRoute()
const showModal = ref(false)

onMounted(() => {
  console.log(route.path)
})

onBeforeRouteUpdate(async (newVal, oldVal) => {
  // only fetch the user if the id changed as maybe only the query or the hash changed
  showModal.value = newVal.meta && newVal.meta.showModal;

   

  if (showModal.value) {
    document.body.classList.add("overflow-hidden");
  } else {
    document.body.classList.remove("overflow-hidden");
  }
})

</script>

<template>
  <div>
    <slot />

    <div v-show="showModal">
      <Overlay2>
        <router-view />
      <!-- </Overlay> -->
      </Overlay2>
    </div>
  </div>
</template>